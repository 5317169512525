import { getId } from '#/object-id';
import parachute from '~/utils/parachute';
import debounce from 'debounce-promise';
import { fetchAll, fetchOne, crudParachute } from '~/utils/crud-functions';
import { flatten, assoc } from 'ramda';
export default {
    name: 'item-search-selector',
    props: {
        itemId: {
            type: String,
        },
        resources: {
            type: Array,
            required: true,
        },
        selectedResource: {
            type: String,
            required: false,
            default() { return this.resources[0]; }
        },
        itemText: {
            type: Function,
            default(item) { return item.name; },
        },
        showResourceTypeInList: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'itemId',
    },
    data() {
        return {
            search: null,
            loading: false,
            items: [],
        };
    },
    watch: {
        itemId: {
            immediate: true,
            handler(value) {
                if (!value) {
                    return;
                }
                this.fetchSingle(this.selectedResource, value);
            },
        },
        search() {
            this.debounceSearch();
        },
    },
    computed: {
        invalid() {
            return !(this.search && this.search.trim().length > 1);
        },
        selected: {
            set(item) {
                if (item) {
                    this.$emit('input', getId(item), item.resource);
                }
                else {
                    this.$emit('input', null, null);
                }
            },
            get() {
                return this.itemId;
            },
        },
    },
    methods: {
        getId,
        fetchSingle: parachute(async function fetchSingle(resource, id) {
            const result = await fetchOne(resource, id);
            this.items.push(assoc('resource', this.selectedResource, result));
        }),
        fetchItems(resource, params) {
            return crudParachute(fetchAll)(resource, params);
        },
        debounceSearch() {
            if (this.loading || this.invalid) {
                return;
            }
            this.loading = true;
            return debounce(this.doSearch, 1500)();
        },
        doSearch() {
            Promise.all(this.resources.map(resource => {
                return this.fetchItems(resource, { filters: { search: this.search } });
            }))
                .then(allResources => {
                return allResources.map((resource, index) => {
                    const resourceName = this.resources[index];
                    return [
                        ...(this.showResourceTypeInList && resource.items.length ? [{ header: this.$tc(`${resourceName}.title`, 2) }] : []),
                        ...(resource.items.map(assoc('resource', resourceName))),
                    ];
                });
            })
                .then(items => {
                this.items = flatten(items);
            })
                .finally(() => {
                this.loading = false;
            });
        },
        onInput(item) {
            if (item) {
                this.$emit('input', getId(item), item.resource);
            }
            else {
                this.$emit('input', null, null);
            }
            this.$nextTick(() => {
                var _a;
                if ((_a = this === null || this === void 0 ? void 0 : this.validate) === null || _a === void 0 ? void 0 : _a.call) {
                    this.validate(getId(item));
                }
            });
        },
    },
};
